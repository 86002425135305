import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { fixTZ } from '../../utils/fix-tz';
import * as dayjs from 'dayjs';

export interface Challenge {
  notify: boolean;
  video_preview: string;
  days_total: number;
  days_current: number;
  is_started: boolean;
  id: number;
  name: string;
  name_html: string;
  description: string;
  join_description: string;
  description_after_join: string;
  start_time: string;
  end_time: string;
  is_active: boolean;
  is_available: boolean;
  groups: string[];
  is_individual: boolean;
  is_course: boolean;
  complexity: string;
  is_joined: boolean;
  complexity_levels: {
    [key: string]: string;
  };
  course_packages: Package[];
  allow_join_while_joined_to_another: boolean;
  color?: string;
  images: Images;
  image_preview: { original: string };
  packages?: Package[];
  cancel_challenges: number[];
  class_start_time: string;
}

export interface PackageMetaInfo {
  current_page_number: number;
  direction: 'desc' | 'asc';
  num_items_per_page: number;
  sort: string;
  total_count: number;
}
export interface PackageStatistics {
  free: number;
  15: number;
  30: number;
  45: number;
}
export interface Images {
  s3_square_160?: string;
  s3_square_240?: string;
  s3_square_256?: string;
  s3_square_300?: string;
  s3_square_400?: string;
  s3_square_600?: string;
  s3_square_800?: string;
  s3_square_1024?: string;
  s3_16x9_320?: string;
  s3_16x9_480?: string;
  s3_16x9_600?: string;
  s3_16x9_800?: string;
  s3_16x9_1200?: string;
  s3_16x9_1600?: string;
  s3_rect_320?: string;
  s3_rect_480?: string;
  s3_rect_600?: string;
  s3_rect_800?: string;
  s3_rect_1200?: string;
  s3_rect_1600?: string;
}

export interface PackageResponse {
  items: Package[];
  meta: PackageMetaInfo;
}

export interface Package {
  package_shortname: string;
  id: number;
  count_lessons: number;
  duration: number;
  name: string;
  description: string;
  youtube_url: string;
  video_url: string;
  is_bought: boolean;
  is_web_bought: boolean;
  price: number;
  price_original: number;
  price_web: number;
  price_web_original: number;
  is_in_bundle: number;
  is_bundle: boolean;
  is_special: boolean;
  is_recurring: boolean;
  is_monthly: boolean;
  discount_in_percent: number;
  images: Images;
  img1x1_images: Images;
  img16x9_images: Images;
  statistic_lessons: PackageStatistics;
  subscription: Subscription;
  package_subscriptions: number;
  subscription_duration: number;
  recommended_challenge_beginner: Challenge;
  recommended_challenge_intermediate: Challenge;
  video_preview: string;
}

@Injectable()
export class PackagesService {
  constructor(private http: HttpClient) {}

  retrieve(): Observable<Package[]> {
    return this.http
      .get<PackageResponse>(`${environment.apiUrl}/api/packages.json`, {
        params: {
          showBundles: true,
          time_zone: fixTZ(dayjs.tz.guess()),
        },
      })
      .pipe(map(response => response.items));
  }
}
