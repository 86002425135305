import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, mergeMap, of, take, throwError } from 'rxjs';

import { IUser } from '../services/user/user.interface';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user/user.service';

@Injectable()
export class UserResolver  {
  constructor(private userService: UserService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IUser> {
    return this.userService.getUser().pipe(
      take(1),
      mergeMap((data) => (data ? of(data) : throwError('no data')))
    );
  }
}
