import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';

import { AccessTokenInterceptor } from './app/core/interceptors/access-token/access-token.interceptor';
import { AppComponent } from './app/app.component';
import { AppRouterModule } from './app/app-router.module';
import { AuthGuard } from './app/core/guards/auth.guard';
import { AuthService } from './app/core/services/auth/auth.service';
import { ErrorInterceptor } from './app/core/interceptors/error/error.interceptor';
import { LessonsService } from './app/core/services/lessons/lessons.service';
import { LoaderInterceptor } from './app/core/interceptors/loader/loader.interceptor';
import { LoaderService } from './app/shared/services/loader-service/loader.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { PackagesService } from './app/core/services/packages/packages.service';
import { ProgramService } from './app/core/services/program/program.service';
import { SubscriptionService } from './app/core/services/subscription/subscription.service';
import { UserResolver } from './app/core/resolvers/user.resolver';
import { UserService } from './app/core/services/user/user.service';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { environment } from './environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideQuillConfig } from 'ngx-quill';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

if (environment.production) {
  enableProdMode();
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

bootstrapApplication(AppComponent, {
  providers: [
    provideQuillConfig({
      theme: 'snow',
      format: 'html',
      modules: {
        toolbar: [
          ['bold', 'italic', 'strike'],
          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          ['link'],
          ['clean'],
        ],
      },
    }),
    importProvidersFrom(BrowserModule, AppRouterModule),
    AuthService,
    AuthGuard,
    UserService,
    ProgramService,
    UserResolver,
    SubscriptionService,
    PackagesService,
    LessonsService,
    LoaderService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch(err => console.error(err));
