// @ts-ignore

import * as changeKeys from 'change-case/keys';

import { Observable, map } from 'rxjs';

import { ChallengeProgram } from './interfaces/challenge-program.interface';
import { GroupParams } from './interfaces/group-params.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LessonGroup } from './interfaces/lesson-group.interface';
import { LessonGroupDay } from './interfaces/lesson-group-day.interface';
import { ProgramEntityResponse } from './interfaces/program-entity-response.interface';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ProgramService {
  constructor(private http: HttpClient) {}

  createLessonGroup({
    package_id,
    duration,
    lessons,
    alternative1,
    alternative2,
  }: GroupParams): Observable<LessonGroup> {
    return this.http.post<LessonGroup>(`${environment.programApiUrl}/api/lesson-groups`, {
      package_id,
      duration,
      alternative1,
      alternative2,
      lessons,
    });
  }

  updateLessonsGroup(data: GroupParams): Observable<LessonGroup> {
    return this.http.put<LessonGroup>(`${environment.programApiUrl}/api/lesson-groups`, data);
  }

  getLessonGroups(): Observable<LessonGroup[]> {
    return this.http
      .get<ProgramEntityResponse<LessonGroup>>(`${environment.programApiUrl}/api/lesson-groups`, {
        params: {},
      })
      .pipe(map(response => response.list));
  }

  getLessonGroup(id: number): Observable<LessonGroup> {
    return this.http.get<LessonGroup>(`${environment.programApiUrl}/api/lesson-groups/${id}`, {
      params: {},
    });
  }

  getChallengePrograms(): Observable<ChallengeProgram[]> {
    return this.http
      .get<ProgramEntityResponse<ChallengeProgram>>(
        `${environment.programApiUrl}/api/challenge-programs`
      )
      .pipe(map(response => response.list));
  }

  getChallengeProgram(id: number): Observable<ChallengeProgram> {
    return this.http
      .get<ChallengeProgram>(`${environment.programApiUrl}/api/challenge-programs/${id}`)
      .pipe(map(response => changeKeys.camelCase(response, 3)));
  }

  createChallengePrograms(program: ChallengeProgram): Observable<ChallengeProgram> {
    const end_time = program.endTime;
    const start_time = program.startTime;

    let convertedProgram = changeKeys.snakeCase(program, 3);

    convertedProgram = {
      ...convertedProgram,
      end_time,
      start_time,
    };

    return this.http
      .post<ChallengeProgram>(
        `${environment.programApiUrl}/api/challenge-programs`,
        convertedProgram
      )
      .pipe(map(response => response));
  }

  updateChallengePrograms(program: ChallengeProgram): Observable<ChallengeProgram> {
    let convertedProgram = changeKeys.snakeCase(program, 3);

    convertedProgram = {
      ...convertedProgram,
      end_time: program.endTime,
      start_time: program.startTime,
      videoPreview: program.videoPreview,
      videoHorizontalPreview: program.videoHorizontalPreview,
    };

    delete convertedProgram['video_preview'];
    delete convertedProgram['video_horizontal_preview'];

    return this.http
      .put<ChallengeProgram>(
        `${environment.programApiUrl}/api/challenge-programs`,
        convertedProgram
      )
      .pipe(map(response => response));
  }

  getLessonGroupProgram(id: number): Observable<LessonGroupDay[]> {
    return this.http
      .get<ProgramEntityResponse<LessonGroupDay[]>>(
        `${environment.programApiUrl}/api/lesson-group-programs`,
        {
          params: {
            challengeProgramId: id,
          },
        }
      )
      .pipe(
        map(response => response.list),
        map(list => changeKeys.camelCase(list, 3))
      );
  }

  createLessonGroupProgram(day: LessonGroupDay): Observable<LessonGroupDay> {
    const fixDay = { ...day, lessonGroupId: day.lessonGroupId ? day.lessonGroupId : 'rest day' };

    return this.http
      .post<LessonGroupDay>(`${environment.programApiUrl}/api/lesson-group-programs`, fixDay)
      .pipe(map(response => response));
  }

  updateLessonGroupProgram(day: LessonGroupDay): Observable<LessonGroupDay> {
    const fixDay = { ...day, lessonGroupId: day.lessonGroupId ? day.lessonGroupId : 'rest day' };

    return this.http
      .put<LessonGroupDay>(`${environment.programApiUrl}/api/lesson-group-programs`, fixDay)
      .pipe(map(response => response));
  }

  deleteLessonGroupProgram(id: number): Observable<void> {
    return this.http
      .delete<void>(`${environment.programApiUrl}/api/lesson-group-programs/${id}`)
      .pipe(map(response => response));
  }
}
