import { ILogin, ILoginResponse } from './auth.interface';
import { Observable, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {}

  login(data: ILogin): Observable<ILoginResponse> {
    return this.httpClient
      .post<ILoginResponse>(`${environment.apiUrl}/authorize`, {
        _username: data.username,
        _password: data.password,
      })
      .pipe(
        tap(response => {
          this.setAccessToken(response.ApiKey);
          this.router.navigate(['/programs']);
        })
      );
  }

  logout(): void {
    localStorage.removeItem('access_token');
    this.router.navigate(['/login']);
  }

  isAuthorized(): boolean {
    return !!localStorage.getItem('access_token');
  }

  setAccessToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  getAccessToken(): string {
    return localStorage.getItem('access_token')!;
  }
}
