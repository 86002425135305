import { Lesson, LessonResponse } from './lessons.models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class LessonsService {
  constructor(private http: HttpClient) {}

  getLessonsForPackage(packageId: number): Observable<Lesson[]> {
    return this.http
      .get<LessonResponse>(`${environment.apiUrlM}/api/v2/lessons`, {
        params: {
          package: packageId,
          sort: 'position',
          direction: 'asc',
          withOneFile: 1,
        },
      })
      .pipe(map(response => response.items as Lesson[]));
  }

  getLessons(): Observable<Lesson[]> {
    return this.http
      .get<LessonResponse>(`${environment.apiUrlM}/api/v2/lessons`, {
        params: {
          sort: 'position',
          direction: 'asc',
          withOneFile: 1,
        },
      })
      .pipe(map(response => response.items as Lesson[]));
  }

  getLessonsV2(): Observable<LessonResponse> {
    return this.http.get<LessonResponse>(`${environment.apiUrlM}/api/v2/lessons`, {
      params: {
        package: 19,
      },
    });
  }
}
